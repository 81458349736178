<div class="container">
    <div class="row">
        <div class="col-12 mb-5">
            <nav-logo></nav-logo>
        </div>
        <div class="col-12 col-md-8 offset-md-2">
            <div class="card shadow-sm">
                <div class="card-body">
                    <h1>Website-Datenschutzinformation</h1>
                    <p>
                        Im Rahmen der Nutzung dieser Website werden personenbezogene Daten von Ihnen durch den jeweils
                        Verantwortlichen verarbeitet und für die Dauer gespeichert, die zur Erfüllung der festgelegten
                        Zwecke und gesetzlichen Verpflichtungen erforderlich ist. Im Folgenden werden Sie darüber
                        informiert, um welche Daten es sich dabei handelt, auf welche Weise sie verarbeitet werden und
                        welche Rechte Ihnen diesbezüglich zustehen, insbesondere im Hinblick auf die
                        Datenschutz-Grundverordnung (EU) 2016/679 (DSGVO). Daten, die als Pflichtangaben gekennzeichnet
                        sind, sind entweder gesetzlich oder vertraglich vorgeschrieben oder für den Vertragsschluss
                        erforderlich. Die Nichtbereitstellung der abgefragten Daten kann für Sie rechtliche oder
                        wirtschaftliche Nachteile haben. So kann es z.B. sein, dass Sie Ihren Antragsprozess nicht
                        fortführen können.
                    </p>
                    <h3>1. Allgemeines zur Datenverarbeitung bei Nutzung der Website</h3>
                    <p>
                        Wenn Sie diese Website besuchen, verarbeitet der jeweils Verantwortliche Ihre Daten, die er im
                        Rahmen der Nutzung der Website erhebt oder die Sie ihm hierbei bereitstellen (im Folgenden:
                        „Ihre Daten“). Zur Bereitstellung der Website und Services des Verantwortlichen werden Ihre
                        Daten mit Auftragsverarbeitern und anderen Auftragnehmern (z.B. aus den Branchen: Logistik,
                        Telekommunikation, Forderungsmanagement, Marketing, Druck, Markt- und Meinungsforschung)
                        ausgetauscht. Zudem tauscht der Verantwortliche jeweils Ihre Daten mit den Gesellschaften der
                        Volkswagen Finanzdienstleistungsgruppe (z.B. Unternehmen aus den Branchen: Bank, Leasing,
                        Versicherung, Mobilität und Tank-/ Servicekarten – im Folgenden: „VW
                        Finanzdienstleistungsgruppe“) aus. Ebenso erfolgt ein Austausch mit öffentlichen Stellen,
                        öffentlich zugänglichen Quellen und ggf. mit Versicherern, Kreditinstituten,
                        Zahlungsdienstleistern und Kooperationspartnern. Die Verarbeitung sowie der Austausch Ihrer
                        Daten findet insbesondere statt, soweit
                    </p>
                    <ul>
                        <li>
                            dies für die Erfüllung eines Vertrages, den Sie geschlossen haben, oder zur Durchführung
                            vorvertraglicher Maßnahmen, die auf Ihre Anfrage hin erfolgen, erforderlich ist (Art. 6 Abs.
                            1 S. 1 lit. b DSGVO). Die Datenverarbeitung ist insbesondere erforderlich, um die
                            Vollständigkeit und Richtigkeit der Daten sowie deren Digitalisierung zu gewährleisten und
                            um ggf. den Vertrag oder dessen Anbahnung durchzuführen;
                        </li>
                        <li>
                            dies zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist (Art. 6 Abs. 1 S. 1 lit.
                            c DSGVO). Die Datenverarbeitung ist insbesondere zur Erfüllung der rechtlichen Verpflichtung
                            des Verantwortlichen im Bereich der Datensicherheit erforderlich;
                        </li>
                        <li>
                            dies zur Wahrung der berechtigten Interessen des Verantwortlichen oder denen eines Dritten
                            erforderlich ist (Art. 6 Abs. 1 S. 1 lit. f DSGVO). Die Datenverarbeitung ist insbesondere
                            erforderlich, um die Bereitstellung und Funktionsfähigkeit der Website gewährleisten und
                            Ihnen die Portale sowie Services bereitstellen zu können, um informierte Entscheidungen der
                            Beteiligten auch in Ihrem Interesse zu gewährleisten und zu optimieren und um eine dauerhaft
                            hohe Qualität und Einheitlichkeit der Kundenberatung durch den Verantwortlichen und die VW
                            Finanzdienstleistungsgruppe zu gewährleisten. Darüber hinaus ist die Datenverarbeitung
                            erforderlich zum Schutz von Vermögenswerten des Verantwortlichen, der VW
                            Finanzdienstleistungsgruppe oder ihrer Kunden sowie zur Erfüllung konzerninterner
                            Verwaltungs- und Abrechnungszwecke. Weiter ist die Datenverarbeitung erforderlich, um die
                            Interessen und Präferenzen der Kunden zu analysieren und zu bewerten und daraus allgemeine
                            Auswertungen für interne Zwecke zu erstellen (z. B. zum betriebswirtschaftlichen Monitoring
                            und zur Optimierung der Produkte);
                        </li>
                        <li>
                            dies von Ihrer freiwillig erteilten Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO) umfasst
                            ist.
                        </li>
                        <li>
                            dies von Ihrer freiwillig erteilten Einwilligung (Art. 9 Abs. 2 lit. a DSGVO) oder einer
                            sonstigen Rechtsgrundlage (Art. 9 Abs. 2 lit. b bis j DSGVO) umfasst ist, wenn Ihre Daten
                            auch besondere Kategorien personenbezogener Daten gemäß Art. 9 Abs. 1 DSGVO (z. B
                            Gesundheitsdaten) enthalten.
                        </li>
                    </ul>

                    <h3>2. Drittlandübermittlung</h3>
                    <p>
                        Der Verantwortliche kann Ihre Daten auch an ein Land außerhalb des Europäischen
                        Wirtschaftsraumes (EWR) übermitteln. Diese Übermittlung erfolgt unter Einhaltung der besonderen
                        Voraussetzungen der Art. 44 - 49 DSGVO, wobei das angemessene Schutzniveau insbesondere entweder
                        durch einen Angemessenheitsbeschluss der europäischen Kommission gemäß Art. 45 DSGVO,
                        abgeschlossene EU-Standardvertragsklauseln gemäß Art. 46 Abs. 2 lit. c und d DSGVO oder
                        verbindliche interne Datenschutzvorschriften gemäß Art. 47 DSGVO gewährleistet wird. Die
                        EU-Standardvertragsklauseln können Sie auf der Website der europäischen Kommission abrufen und
                        einsehen oder direkt beim Verantwortlichen erfragen und in Kopie erhalten.
                    </p>

                    <h3>3. Allgemeine Speicherfristen</h3>
                    <p>
                        Die allgemeine Dauer der Speicherung Ihrer Daten ist abhängig von einem möglichen
                        Vertragsschluss über die Website und von der Beendigung des Vertrages.
                    </p>

                    <p>
                        Sollten Sie sich zu Produkten/Dienstleistungen des Verantwortlichen informieren, aber keinen
                        Vertrag angebahnt haben, werden Ihre Daten längstens für 6 Monate nach dem letztmaligen Kontakt
                        zwischen Ihnen und dem Verantwortlichen gespeichert.
                    </p>

                    <p>
                        Im Übrigen beginnt die Speicherfrist am Ende des Kalenderjahres des letzten Ereignisses im
                        Rahmen dieser Geschäftsbeziehung und es gelten folgende Fristen:
                    </p>
                    <ul>
                        <li>
                            Wenn es zu einer Vertragsanbahnung, aber zu keinem Vertragsschluss gekommen ist, werden Ihre
                            Daten längstens für 3 Jahre gespeichert.
                        </li>
                        <li>
                            Ihre für einen Vertrag relevanten personenbezogenen Daten, insbesondere steuerrechtlich
                            relevante Daten, werden nach Ablauf der gesetzlichen Aufbewahrungsfristen, spätestens 10
                            Jahre nach Beendigung des Vertrages, gelöscht. Die allgemeine Speicherdauer von Ihren
                            personenbezogenen Daten kann ausnahmsweise bis zu 30 Jahre betragen, soweit dies zur
                            Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist.
                        </li>
                    </ul>
                    <p>
                        Auf abweichende Speicherfristen bei einzelnen Datenkategorien werden Sie innerhalb dieser
                        Datenschutzinformation am Ende des jeweiligen Abschnitts hingewiesen. Die Speicherfristen können
                        sich ausnahmsweise verlängern, wenn die weitere Speicherung rechtlich zulässig (z. B. zum Schutz
                        von Vermögenswerten oder zur Wahrung und ggf. Durchsetzung der Rechte des Verantwortlichen) oder
                        erforderlich ist (z. B. Aufbewahrungsfrist für Werbeeinwilligungen oder im Rahmen von
                        Betriebsprüfungen).
                    </p>

                    <h3>4. Nutzung eines Kontaktformulars</h3>
                    <p>
                        Sie haben jeweils die Möglichkeit mit dem Verantwortlichen über ein Webformular in Kontakt zu
                        treten, bei dem dieser die von Ihnen eingegebenen Daten verarbeitet, um Ihre Anfrage bearbeiten
                        zu können. Die Verarbeitung zu diesem Zweck erfolgt, soweit
                    </p>
                    <ul>
                        <li>
                            dies für die Erfüllung des Vertrages, den Sie geschlossen haben, oder zur Durchführung
                            vorvertraglicher Maßnahmen, die auf Ihre Anfrage hin erfolgen, erforderlich ist (Art. 6 Abs.
                            1 S. 1 lit. b DSGVO). Die Datenverarbeitung ist insbesondere erforderlich, um die
                            Vollständigkeit und Richtigkeit der Daten sowie deren Digitalisierung zu gewährleisten und
                            um den Vertrag durchzuführen;
                        </li>
                        <li>
                            dies zur Wahrung der berechtigten Interessen des Verantwortlichen oder denen eines Dritten
                            erforderlich ist (Art. 6 Abs. 1 S. 1 lit. f DSGVO). Die Datenverarbeitung ist insbesondere
                            erforderlich, um Ihr Anliegen bearbeiten zu können;
                        </li>
                        <li>
                            dies von Ihrer freiwillig erteilten Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO) umfasst
                            ist;
                        </li>
                        <li>
                            dies von Ihrer freiwillig erteilten Einwilligung (Art. 9 Abs. 2 lit. a DSGVO) oder einer
                            sonstigen Rechtsgrundlage (Art. 9 Abs. 2 lit. b bis j DSGVO) umfasst ist, wenn Ihre Daten
                            auch besondere Kategorien personenbezogener Daten gemäß Art. 9 Abs. 1 DSGVO (z. B
                            Gesundheitsdaten) enthalten.
                        </li>
                    </ul>

                    <h3>5. Nutzung eines Log-in-Bereichs</h3>
                    <p>
                        Bei Einrichtung und Nutzung eines passwortgeschützten Benutzerkontos verarbeitet der
                        Verantwortliche die von Ihnen eingegebenen Daten, um Ihnen Zugang zu seinen Portalen sowie zu
                        deren Funktionen und Services zu ermöglichen. Die Verarbeitung zu diesem Zweck erfolgt, soweit
                    </p>
                    <ul>
                        <li>
                            dies zur Erfüllung eines Vertrages, den Sie geschlossen haben, oder zur Durchführung
                            vorvertraglicher Maßnahmen, die auf Ihre Anfrage hin erfolgen, erforderlich ist (Art. 6 Abs.
                            1 S. 1 lit. b DSGVO). Die Datenverarbeitung ist insbesondere erforderlich, um die
                            Vollständigkeit und Richtigkeit der Daten sowie deren Digitalisierung zu gewährleisten und
                            um den Vertrag durchzuführen;
                        </li>
                        <li>
                            dies zur Wahrung der berechtigten Interessen des Verantwortlichen oder denen eines Dritten
                            erforderlich ist (Art. 6 Abs. 1 S. 1 lit. f DSGVO). Die Datenverarbeitung ist insbesondere
                            erforderlich, um Ihnen die Portale und deren Services bereitstellen zu können;
                        </li>
                        <li>
                            dies von Ihrer freiwillig erteilten Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO) umfasst
                            ist;
                        </li>
                        <li>
                            dies von Ihrer freiwillig erteilten Einwilligung (Art. 9 Abs. 2 lit. a DSGVO) oder einer
                            sonstigen Rechtsgrundlage (Art. 9 Abs. 2 lit. b bis j DSGVO) umfasst ist, wenn Ihre Daten
                            auch besondere Kategorien personenbezogener Daten gemäß Art. 9 Abs. 1 DSGVO (z. B
                            Gesundheitsdaten) enthalten.
                        </li>
                    </ul>
                    <p>
                        Die Dauer der Speicherung Ihrer Daten ist abhängig von Ihrem Benutzerkonto. Mit der Löschung des
                        Benutzerkontos werden Ihre Daten ebenfalls grundsätzlich gelöscht. Im Übrigen gelten die
                        „Allgemeinen Speicherfristen“.
                    </p>

                    <h3>6. Kundenprofile</h3>
                    <p>
                        Der Verantwortliche bildet auf Grundlage Ihrer Daten und weiterer werberelevanter Daten Ihr
                        individuelles Kundenprofil und ordnet dieses bestimmten Kundensegmenten zu. Diese Ergebnisse
                        nutzt der Verantwortliche für die zielgruppenspezifische Steuerung von Art, Inhalt und
                        Häufigkeit von Werbemaßnahmen. Dies kann insbesondere dazu führen, dass Sie im Unterschied zu
                        anderen Kunden bestimmte Werbung erhalten oder nicht erhalten. Dies geschieht, soweit
                    </p>
                    <ul>
                        <li>
                            dies zur Wahrung berechtigter Interessen des Verantwortlichen oder denen eines Dritten
                            erforderlich ist (Art. 6 Abs. 1 S. 1 lit. f DSGVO). Die Datenverarbeitung ist insbesondere
                            erforderlich, um anhand einfacher Auswertungen von Nutzungsdaten und offensichtlicher
                            Präferenzen Angebote besser auf Sie zuschneiden zu können und unerwünschte oder unpassende
                            Angebote zu vermeiden (eingeschränkte Auswertungen);
                        </li>
                        <li>
                            dies von Ihrer freiwillig erteilten Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO) umfasst
                            ist (umfangreiche Auswertungen).
                        </li>
                    </ul>

                    <h3>7. Individuelle Werbemaßnahmen</h3>
                    <p>
                        Auf dieser Website verarbeitet der Verantwortliche Ihre Daten für Direktwerbung, sowohl für
                        eigene Angebote als auch für Angebote der VW Finanzdienstleistungsgruppe, Ihres Handelspartners
                        und des Herstellers, des Importeurs oder der Vertriebsgesellschaft Ihres Fahrzeuges oder von
                        sonstigen berechtigten Dritten (z.B. Unternehmen aus den Branchen: Bank, Leasing, Versicherung
                        und Mobilität), und tauscht dazu mit den vorgenannten Empfängern Ihre Daten aus. Daneben nutzt
                        der Verantwortliche Online-Marketingdienste, um bestimmten Zielgruppen bei ihrer Nutzung von
                        Online-Diensten interessengerechte Werbeinhalte anzuzeigen. Dazu übermittelt der Verantwortliche
                        Ihre Daten (z.B. E-Mailadresse) in verschlüsselter Form (Hash-Wert) an die
                        Online-Marketingdienste. Diese gleichen den Hash-Wert der übermittelten Daten mit den Hashwerten
                        ihrer eigenen Nutzerdaten zur Identifikation ab. Dies geschieht jeweils, soweit
                    </p>
                    <ul>
                        <li>
                            dies zur Wahrung berechtigter Interessen des Verantwortlichen oder eines Dritten
                            erforderlich ist (Art. 6 Abs. 1 S. 1 lit. f DSGVO). Die Datenverarbeitung ist insbesondere
                            erforderlich, um Ihnen auf Sie zugeschnittene Angebote zeitnah und zuverlässig zukommen
                            lassen zu können und Ihnen auf Sie zugeschnittene Online-Werbung anzuzeigen;
                        </li>
                        <li>
                            dies von Ihrer freiwillig erteilten Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO) umfasst
                            ist.
                        </li>
                    </ul>

                    <p>
                        Ein Austausch mit sonstigen Empfängern erfolgt nur, soweit dies von Ihrer freiwillig erteilten
                        Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO) umfasst ist.
                    </p>

                    <h3>8. Nutzung einer Online-Antragsstrecke</h3>
                    <p>
                        Wenn Sie auf dieser Website ein Produkt über die Online-Antragsstrecken abschließen möchten,
                        werden die von Ihnen eingegebenen Daten nach jedem Absendevorgang zwischengespeichert, um einen
                        Missbrauch der Antragsstrecken zu verhindern, die Sicherheit der informationstechnischen Systeme
                        sicherzustellen und Ihnen im Störungsfall weiterhelfen zu können. Die Verarbeitung zu den o.g.
                        Zwecken erfolgt, soweit
                    </p>
                    <ul>
                        <li>
                            dies zur Erfüllung eines Vertrages, den Sie geschlossen haben, oder zur Durchführung
                            vorvertraglicher Maßnahmen, die auf Ihre Anfrage hin erfolgen, erforderlich ist (Art. 6 Abs.
                            1 S. 1 lit. b DSGVO). Die Datenverarbeitung ist insbesondere erforderlich, um die
                            Vollständigkeit und Richtigkeit der Daten sowie deren Digitalisierung zu gewährleisten und
                            um den Vertrag durchzuführen;
                        </li>
                        <li>
                            dies zur Wahrung der berechtigten Interessen des Verantwortlichen oder denen eines Dritten
                            erforderlich ist (Art. 6 Abs. 1 S. 1 lit. f DSGVO). Die Datenverarbeitung ist insbesondere
                            erforderlich, um Ihr Anliegen bearbeiten und die Funktionsfähigkeit der Systeme
                            gewährleisten zu können;
                        </li>
                        <li>
                            dies von Ihrer freiwillig erteilten Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO) umfasst
                            ist;
                        </li>
                        <li>
                            dies von Ihrer freiwillig erteilten Einwilligung (Art. 9 Abs. 2 lit. a DSGVO) oder einer
                            sonstigen Rechtsgrundlage (Art. 9 Abs. 2 lit. b bis j DSGVO) umfasst ist, wenn Ihre Daten
                            auch besondere Kategorien personenbezogener Daten gemäß Art. 9 Abs. 1 DSGVO (z. B
                            Gesundheitsdaten) enthalten.
                        </li>
                    </ul>

                    <h3>9. Cookies</h3>
                    <p>
                        Der Verantwortliche setzt auf der Website Cookies ein. Hierbei handelt es sich um kleine
                        Dateien, die Ihr Browser automatisch erstellt und die auf Ihrem Endgerät gespeichert werden,
                        wenn Sie die Website besuchen. Cookies richten auf Ihrem Endgerät keinen Schaden an, enthalten
                        keine Viren, Trojaner oder sonstige Schadsoftware.
                    </p>
                    <p>
                        In dem Cookie werden Informationen abgelegt, die sich jeweils im Zusammenhang mit dem spezifisch
                        eingesetzten Endgerät ergeben. Dies bedeutet jedoch nicht, dass der Verantwortliche dadurch
                        unmittelbar Kenntnis von Ihrer Identität erhalten.
                    </p>
                    <p>
                        Die meisten Browser akzeptieren Cookies automatisch. Sie können Ihren Browser jedoch so
                        konfigurieren, dass keine Cookies auf Ihrem Computer gespeichert werden oder stets ein Hinweis
                        erscheint, bevor ein neues Cookie angelegt wird. Die vollständige Deaktivierung von Cookies kann
                        jedoch dazu führen, dass Sie nicht alle Funktionen der Website nutzen können. Cookies dienen
                        dazu, die Nutzung des Angebots statistisch zu erfassen und für Sie nutzerfreundlicher und
                        effektiver zu gestalten. So setzt der Verantwortliche sogenannte Session-Cookies ein, um zu
                        erkennen, dass Sie einzelne Seiten des Internetauftritts bereits besucht haben.
                    </p>
                    <p>
                        Die darin gespeicherten Daten (z.B. Session-ID, Zeitpunkt des Seitenaufrufs) werden nach Ende
                        Ihres Besuchs automatisch gelöscht. Einige Cookies bleiben dagegen auf Ihrem Endgerät
                        gespeichert, bis Sie diese löschen oder die für das Cookie definierte Gültigkeitsdauer abläuft.
                    </p>
                    <p>
                        Ferner setzt der Verantwortliche auf dieser Website Tracking-Maßnahmen ein, um eine
                        bedarfsgerechte Gestaltung und fortlaufende Optimierung der Website sicherzustellen sowie deren
                        Nutzung statistisch zu erfassen. Zudem nutzt der Verantwortliche, sowie andere Gesellschaften
                        der Volkswagen Finanzdienstleistungsgruppe, z.B. die Euro Leasing GmbH, die Daten für die
                        optimale Ausspielung von Werbeinhalten.
                    </p>
                    <p>
                        Die Verwendung von Cookies erfolgt, soweit
                    </p>
                    <ul>
                        <li>
                            dies aus technischen Gründen oder für die Funktionalität oder Optimierung der Webseite bzw.
                            des von Ihnen gewünschten Dienstes unbedingt erforderlich ist (§ 25 Abs. 2 Nr. 2
                            Telekommunikation-Telemedien-Datenschutzgesetz).
                        </li>
                        <li>
                            dies von Ihrer freiwillig erteilten Einwilligung (§ 25 Abs. 1
                            Telekommunikation-Telemedien-Datenschutzgesetz; Art. 6 Abs. 1 S. 1 lit. a DSGVO) umfasst
                            ist;
                        </li>
                        <li>
                            dies von Ihrer freiwillig erteilten Einwilligung ((§ 25 Abs. 1
                            Telekommunikation-Telemedien-Datenschutzgesetz; Art. 9 Abs. 2 lit. a DSGVO) wenn Ihre Daten
                            auch besondere Kategorien personenbezogener Daten gemäß Art. 9 Abs. 1 DSGVO (z. B
                            Gesundheitsdaten) enthalten.
                        </li>
                    </ul>

                    <p>
                        Sie können Ihre einmal erteilte Einwilligung jederzeit mit Wirkung für die Zukunft, entweder
                        über das Cookie Consent Tool und/oder über den jeweils am Ende der einzelnen Analyse-Cookies
                        aufgeführten Link zum Drittanbieter, widerrufen. In beiden Fällen wird ein Opt-out-Cookie
                        gesetzt, das die zukünftige Erfassung Ihrer Daten beim Besuch dieser Website verhindert. Wenn
                        Sie die Verarbeitung Ihrer Daten über das Cookie Consent Tool widerrufen, gilt dies nur in
                        diesem Browser und nur für die Website des Verantwortlichen und wird auf Ihrem Gerät abgelegt.
                    </p>

                    <h3>10. Betroffenenrechte</h3>
                    <p>
                        Sie haben das Recht:
                    </p>
                    <ul>
                        <li>gemäß Art. 15 DSGVO Auskunft über Ihre von dem Verantwortlichen verarbeiteten
                            personenbezogenen Daten zu verlangen;
                        </li>
                        <li>gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer
                            beim Verantwortlichen gespeicherten personenbezogenen Daten zu verlangen;
                        </li>
                        <li>gemäß Art. 17 DSGVO die Löschung Ihrer beim Verantwortlichen gespeicherten personenbezogenen
                            Daten zu verlangen, sofern die gesetzlichen Voraussetzungen vorliegen;
                        </li>
                        <li>gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
                            verlangen, sofern die gesetzlichen Voraussetzungen vorliegen;
                        </li>
                        <li>gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie dem Verantwortlichen
                            bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu
                            erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen;
                        </li>
                        <li>gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit gegenüber dem
                            Verantwortlichen zu widerrufen;
                        </li>
                        <li>gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie
                            sich hierfür an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder unseres
                            Unternehmenssitzes wenden.
                        </li>
                    </ul>
                    <p>
                        Je nachdem zu welchem Produkt Sie sich auf dieser Website informiert oder welchen Service Sie
                        genutzt haben, finden Sie nachfolgend die Kontaktdaten des jeweils Verantwortlichen.
                    </p>

                    <div class="border p-2 m-3">
                        <h3>Widerspruchsrecht</h3>
                        <p>
                            <strong>
                                Sie haben das Recht, gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung Ihrer
                                personenbezogenen Daten einzulegen, soweit dafür Gründe vorliegen, die sich aus Ihrer
                                besonderen Situation ergeben oder sich der Widerspruch gegen allgemeine oder auf Sie
                                zugeschnittene Direktwerbung richtet. Im letzteren Fall haben Sie ein generelles
                                Widerspruchsrecht, das ohne Angabe einer besonderen Situation umgesetzt wird.
                            </strong>
                        </p>
                        <p>
                            <strong><u>Verantwortlicher Volkswagen Bank GmbH:</u></strong>
                        </p>
                        <p>
                            Postanschrift des Verantwortlichen und des Datenschutzbeauftragten:
                            <br>
                            Volkswagen Bank GmbH
                            <br>
                            Gifhorner Straße 57
                            <br>
                            38112 Braunschweig
                        </p>
                        <p>
                            Möchten Sie von Ihrem Widerspruchsrecht oder von Ihren Betroffenenrechten Gebrauch machen,
                            genügt eine E-Mail an betroffenenrechte[at]volkswagenbank.de. Darüberhinausgehender Kontakt
                            zum Datenschutzbeauftragten: dsb[at]volkswagenbank.de
                        </p>
                    </div>

                    <h3>11. Aktualität und Änderung dieser Datenschutzinformation</h3>
                    <p>
                        Diese Datenschutzinformation ist aktuell gültig und hat den Stand April 2022.
                    </p>
                    <p>
                        Durch die Weiterentwicklung der Website und Angebote darüber oder aufgrund geänderter
                        gesetzlicher bzw. behördlicher Vorgaben kann es notwendig werden, diese Datenschutzinformation
                        zu aktualisieren. Die jeweils aktuelle Datenschutzinformation können Sie jederzeit auf der
                        Webseite abrufen und ausdrucken.
                    </p>

                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>