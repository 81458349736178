<div class="c2-overlay" (click)="closeOverlay($event)">
  <div class="c2-wizard-container" [class.gaussed]="loading">
    <div class="row">
      <div class="col-12 text-center">
        <div class="logo"></div>
      </div>
      <div class="col-12 mt-4 text-center" *ngIf="!inputToggled">
        <div class="calc__desired-amount-label">
          <strong>Wunschbetrag eingeben</strong>
        </div>
        <div class="calc__chevron--spacing">
          <a (click)="inputToggled = !inputToggled">
            <span class="material-icons calc__chevron--size">expand_more</span>
          </a>
        </div>
      </div>
      <div class="col-12 text-center" *ngIf="!inputToggled">
        <div class="col-12 text-center my-0 calc__desired-amount">
          <a id="calc-desired-amount-link" (click)="inputToggled = !inputToggled">
            {{ amount | currencyPostfix }}
          </a>
          <hr class="text-center calc-desired-amount-underline"/>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-8 offset-2 mt-1" *ngIf="inputToggled">
        <form [formGroup]="amountForm" (ngSubmit)="submit();inputToggled = !inputToggled">
          <mat-form-field appearance="outline" class="w-100 btn-suffix">
            <mat-label>EUR</mat-label>
            <input matInput type="number" placeholder="EUR" focus max="{{terms.limit}}" formControlName="amountControl"
                   min="{{terms.minPurchaseAmount}}" class="calc__desired-amount">
            <button matSuffix mat-raised-button class="calc__desired-amount-button" color="accent" type="submit"
                    [disabled]="!amountForm.valid">
              OK
            </button>
            <mat-hint *ngIf="terms?.limit">
              Max. {{ terms.limit| currencyPostfix }}
            </mat-hint>
          </mat-form-field>
        </form>
      </div>
    </div>
    <app-show-plans [terms]="terms" [annuities]="annuities"></app-show-plans>
    <div class="row">
      <div class="col-12 text-center mt-3">
        <button mat-button class="text-primary" (click)="closeFrame()">
          Schliessen
        </button>
      </div>
    </div>
  </div>
  <div class="text-center spinner" *ngIf="loading">
    <div class="spinner-container">
      <img src="assets/icons/spinner.svg" class="rotate-center" width="70" alt="Spinner">
      <div class="mt-5">
        <strong>Wir rechnen gerade,<br> bitte haben Sie einen Moment Geduld.</strong>
      </div>
    </div>
  </div>
</div>

<script type="text/javascript">
    if (window.addEventListener) {
        window.addEventListener("message", onMessage, false);
    } else if (window.attachEvent) {
        window.attachEvent("onmessage", onMessage, false);
    }

    function onMessage(event) {

        var url = (window.location != window.parent.location)
            ? document.referrer
            : document.location.href;
        url = url.replace(/\/$/, "");

        if (!url.startsWith(event.origin)) {
            return;
        }

    }
</script>