import { HttpHeaders } from '@angular/common/http';
import {ErrorHandler, Injectable} from '@angular/core';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class C2ErrorHandler implements ErrorHandler {

  handleError(error: any): void {
    console.log(error);
  }

}