import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TrackingComponent} from './tracking/tracking.component';


@NgModule({
  declarations: [
    TrackingComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    TrackingComponent
  ]
})
export class TrackingModule {
}
