import {AfterViewInit, Component} from '@angular/core';
import {environment} from '@env/environment';
import {TrackingService} from '@app/shared/tracking/tracking.service';

@Component({
  selector: 'app-tracking',
  template: ``
})
export class TrackingComponent implements AfterViewInit {

  private production = environment.production;
  private mode = environment.mode;
  private product = environment.productType;

  private readonly liveScript = 'https://assets.adobedtm.com/e623380c0b69/a45ff0e69867/launch-EN297f6069de8b4a75961ef39a0fb96373.min.js';
  private readonly stagingScript = 'https://assets.adobedtm.com/e623380c0b69/a45ff0e69867/launch-ENccb5ccee3da747bc98ede97ee6d58d75-staging.min.js';

  constructor(private tracking: TrackingService) {
    // inject TrackingService to setup Tracking object
  }

  ngAfterViewInit(): void {
    if (this.product === 'SOFORTKREDIT') {
      this.addTracking();
    }
  }

  private addTracking() {
    if (this.mode === 'local') {
      return;
    }

    if (this.production) {
      this.addScript('adobe-live', this.liveScript);
    } else {
      this.addScript('adobe-staging', this.stagingScript);
    }
  }

  private addScript(env: string, src: string) {
    if (document.getElementById(env)) {
      return;
    }

    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    script.id = env;
    document.getElementsByTagName('head')[0].appendChild(script);
  }

}