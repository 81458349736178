import {registerLocaleData} from '@angular/common';

import localeDe from '@angular/common/locales/de';
import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule, Title} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CoreModule} from '@app/core/core.module';
import {C2ErrorHandler} from '@app/core/error/c2-error-handler.service';
import {ExampleCalculatorComponent} from '@app/marketing/example-calculator/example-calculator.component';
import {
  ExternalInlineCalculatorComponent
} from '@app/marketing/external-inline-calculator/external-inline-calculator.component';
import {ComplaintHandlingComponent} from '@app/marketing/tac/complaint-handling/complaint-handling.component';
import {ConciliationBoardComponent} from '@app/marketing/tac/conciliation-board/conciliation-board.component';
import {TermsOfUseComponent} from '@app/marketing/tac/terms-of-use/terms-of-use.component';
import {SharedModule} from '@app/shared/shared.module';
import {TriggerSimulationService} from '@app/simulation/trigger-simulation.service';
import {AppRoutingModule} from './app-routing.module';

import {AppComponent} from './app.component';
import {ImpressumComponent} from './marketing/impressum/impressum.component';
import {OverlayCalculatorComponent} from './marketing/overlay-calculator/overlay-calculator.component';
import {DataPrivacyHomepageComponent} from './marketing/tac/data-privacy-homepage/data-privacy-homepage.component';
import {DataPrivacyComponent} from './marketing/tac/data-privacy/data-privacy.component';
import {TacComponent} from './marketing/tac/tac.component';
import {PurchaseLoadComponent} from './purchase-load.component';
import {SimulationComponent} from './simulation/simulation.component';
import {
  DataPrivacyHomepageSkComponent
} from './marketing/tac/data-privacy-homepage/data-privacy-homepage-sk/data-privacy-homepage-sk.component';
import {
  DataPrivacyHomepageFabComponent
} from './marketing/tac/data-privacy-homepage/data-privacy-homepage-fab/data-privacy-homepage-fab.component';
import {TrackingModule} from '@app/shared/tracking/tracking.module';


registerLocaleData(localeDe);

@NgModule({
  declarations: [
    AppComponent,
    PurchaseLoadComponent,
    ImpressumComponent,
    TacComponent,
    OverlayCalculatorComponent,
    ExampleCalculatorComponent,
    ExternalInlineCalculatorComponent,
    DataPrivacyHomepageComponent,
    DataPrivacyComponent,
    TermsOfUseComponent,
    ConciliationBoardComponent,
    ComplaintHandlingComponent,
    SimulationComponent,
    DataPrivacyHomepageSkComponent,
    DataPrivacyHomepageFabComponent
  ],
  imports: [
    CoreModule,
    SharedModule,

    BrowserAnimationsModule,
    BrowserModule,

    AppRoutingModule,
    TrackingModule
  ],
  providers: [
    TriggerSimulationService,
    {provide: LOCALE_ID, useValue: 'de-DE'},
    {provide: ErrorHandler, useClass: C2ErrorHandler},
    Title
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor() {
  }
}
