import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BackendService} from '@app/core';
import {BasePurchaseInfoResponse} from '@app/core/backend/response/base-purchase-info.response';
import {TriggerSimulationService} from '@app/simulation/trigger-simulation.service';
import {StringUtil} from '@app/util/static-services/string.util';

@Component({
  selector: 'app-simulation',
  templateUrl: './simulation.component.html',
  styleUrls: ['./simulation.component.css']
})
export class SimulationComponent implements OnInit {

  private shortCode: string;

  private key: string;

  requestState = {loading: true, error: null, success: false, referenceId: undefined};

  purchaseInfo: BasePurchaseInfoResponse;

  constructor(private backendService: BackendService,
              private triggerService: TriggerSimulationService,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe(next => {
      this.shortCode = next.get('sc');
      this.key = next.get('key');
      if (StringUtil.isNotBlank(this.shortCode) && StringUtil.isNotBlank(this.key)) {
        this.trigger();
      } else {
        this.requestState.error = {invalidParams: true};
        this.requestState.loading = false;
      }
    });
  }

  private trigger() {
    this.backendService.getBasePurchaseInfo(this.shortCode, this.key).subscribe(next => {
      this.purchaseInfo = next;
      this.requestState.loading = false;
    }, error => this.requestState.error = error);
  }


  triggerCallback(callback: String) {
    const request = {referenceId: this.purchaseInfo.transactionId, type: callback};
    this.triggerService.triggerSimulation(request).subscribe(
      next => this.requestState = {...this.requestState, success: true, referenceId: next.referenceId } ,
      err => this.requestState.error = err
    );
  }
}
