<div class="container">
  <div class="row">
    <div class="col-12 mb-5">
      <nav-logo></nav-logo>
    </div>
    <div class="col-12 col-md-8 offset-md-2">
      <div class="card shadow-sm">
        <div class="card-body">
          <h1>Nutzungsbedingungen für {{BRAND_NAME}} (Kunden)</h1>
          <p>
            Die Volkswagen Bank GmbH, Gifhorner Str. 57, 38112 Braunschweig (nachfolgend „VW Bank“ oder
            „wir“) eingetragen im Handelsregister des Amtsgerichts Braunschweig unter HRB 1819 betreibt
            unter <a [href]="MAIN_URL" target="_blank">{{MAIN_URL}}</a>
            die Internetplattform {{BRAND_NAME}} (nachfolgend „die Plattform“). Über die Plattform erhalten Kunden
            registrierter Händler die Möglichkeit, eine Finanzierungsanfrage für Kauf- und Reparaturvorgänge (bspw.
            Zubehör, Reparatur) bei der VW Bank zu stellen.
          </p>
          <p>
            Diese Bedingungen regeln die Nutzung der Plattform durch registrierte Kunden (nachfolgend „Kunden“ oder
            „Nutzer“).
          </p>
          <h3>1. Vertragsgegenstand und Geltungsbereich</h3>
          <ul>
            <p>
              1.1 Der Nutzer erhält über die Plattform die Möglichkeit, für bestimmte Kaufvorgänge (bspw. Zubehör) oder
              Werkverträge (Reparatur) einen Finanzierungsvertrag mit der VW Bank abzuschließen und seine Vertragsdaten
              während der Laufzeit einzusehen und zu ändern.
            </p>
            <p>
              1.2 Soweit ein Kunde im Zusammenhang mit dem Kauf und/oder Werkvertrag gegenüber einem registrierten
              Händler Interesse an einer Finanzierung des Kaufpreises und/oder Werklohns durch die VW Bank bekundet,
              erlaubt die Plattform dem Händler, einen Vorgang anzulegen. Der Kunde erhält dadurch die Möglichkeit, nach
              eigener Registrierung auf der Plattform einen Finanzierungsvertrag mit der VW Bank abzuschließen. Ferner
              bietet die Plattform dem Nutzer die Möglichkeit, den Status der Finanzierung zu prüfen.
            </p>
            <p>
              1.3 Diese Nutzungsbedingungen regeln die Nutzung der Plattform als solche. Die Leistungen, Rechte und
              Pflichten der VW Bank und des Kunden im Rahmen eines etwaigen Abschlusses eines Finanzierungsvertrages
              über die Plattform sind Gegenstand separater Vertragsbedingungen, die dem Kunden bei Vertragsschluss
              mitgeteilt werden.
            </p>
            <p>
              1.4 Der Kunde hat keinen Anspruch auf den Abschluss eines Finanzierungsvertrages zu bestimmten
              Konditionen. Der Kunde erhält über die Plattform lediglich die Möglichkeit, eine Finanzierung zu
              beantragen. Die VW Bank behält sich vor, einen Vertragsschluss von bestimmten Anforderungen abhängig zu
              machen (bspw. erfolgreiche Bonitätsprüfung) oder im eigenen Ermessen abzulehnen.
            </p>
            <p>
              1.5 Diese Nutzungsbedingungen ersetzen ab dem Datum ihres Wirksamwerdens alle früheren Vereinbarungen für
              die Nutzung der Plattform und/oder von Vorgängerdiensten.
            </p>
            <p>
              1.6 Der Vertrag über die Nutzung der Plattform kommt mit der Registrierung des Nutzers zustande. Dafür hat
              der Nutzer seine Stammdaten entsprechend auf der Plattform anzugeben.
            </p>
            <p>
              1.7 Teilweise bindet die VW Bank auf der Plattform Dienste von Drittanbietern an (bspw. zur
              Bonitätsprüfung, zur elektronische Unterzeichnung). Diese Dienste unterliegen ggfs. gesonderten
              Nutzungsbedingungen der Drittanbieter, auf die die VW Bank im Zusammenhang mit dem Einsatz des jeweiligen
              Dienstes gesondert hinweisen wird.
            </p>
          </ul>
          <h3>2. Bereitstellung der Plattform</h3>
          <ul>
            <p>
              2.1 Die VW Bank stellt den nicht ausschließlichen und nicht übertragbaren Zugang zur Plattform in der
              jeweils aktuellen Version während der Vertragslaufzeit unentgeltlich bereit.
            </p>
            <p>
              2.2 Der Nutzer kann die Plattform über die Internet-Adresse <a [href]="MAIN_URL"
                target="_blank">{{MAIN_URL}}</a>
              erreichen.
            </p>
            <p>
              2.3 Der Nutzer schafft auf seiner Seite die technischen Voraussetzungen für die Nutzung der Plattform. In
              der Regel sind ein Internet-Zugang und ein marktüblicher Browser erforderlich. Die VW Bank kann den Zugang
              zur Plattform für einen bestimmten
              Browser optimieren. Der Nutzer muss ggfs. von der VW Bank vorgegebene Sicherheitseinstellungen vornehmen,
              um die Plattform benutzen zu können Der Nutzer wird Browser und Betriebssystem der aufrufenden Rechner auf
              dem jeweils technisch aktuellen Stand halten.
            </p>
            <p>
              2.4 Die Plattform ist bei schlechter Internetverbindung ggfs. nur eingeschränkt nutzbar, insbesondere sind
              dann die Reaktionszeiten möglicherweise spürbar verlangsamt.
            </p>
            <p>
              2.5 Die VW Bank behält sich vor, die Plattform laufend weiterzuentwickeln und zu verbessern, insbesondere
              durch eventuelle Updates und Upgrades.
            </p>
            <p>
              2.6 Der Nutzer hat keinen Anspruch auf eine ständige Verfügbarkeit der Plattform. Die VW Bank behält sich
              vor, die Plattform (bspw. zu Wartungszwecken) offline zu nehmen oder die über die Plattform angebotenen
              Dienste auszusetzen oder endgültig einzustellen. Insbesondere hat die VW Bank keinen Einfluss auf die
              Funktionsfähigkeit öffentlicher Netze und/oder die Verfügbarkeit und Nutzbarkeit von -Technologien
              und/oder Diensten Dritter.
            </p>
          </ul>
          <h3>3. Nutzungsumfang und Nutzungsrechte</h3>
          <ul>
            <p>
              3.1 Der Nutzer wird die Plattform ausschließlich auf dem dafür vorgesehenen Wege, für die vorgesehenen
              Zwecke und in angemessenem Umfang nutzen.
            </p>
            <p>
              3.2 Der Nutzer erhält an der Plattform sowie an den ggfs. enthaltenen Materialien, Dokumenten und
              sonstigen Inhalten der VW Bank ein Einfaches, nicht übertragbares, zeitlich auf die Vertragslaufzeit und
              inhaltlich auf die bestimmungsgemäße Nutzung der Plattform beschränktes Nutzungsrecht. Im Übrigen ist es
              dem Nutzer ohne vorherige schriftliche Zustimmung der VW Bank nicht gestattet, die vorstehende Plattform
              und/oder sonstige Inhalte der VW Bank zu nutzen. Der Nutzer hat abseits der bestimmungsgemäßen Nutzung der
              Plattform insbesondere kein Recht, die vorstehenden Gegenstände und Inhalte zu vervielfältigen, zu
              veröffentlichen, weiterzugeben oder anderweitig an Dritte zu überlassen und/oder zu bearbeiten. Die VW
              Bank behält sich sämtliche vorstehenden Rechte ausdrücklich vor.
            </p>
            <p>
              3.3 Nicht von der bestimmungsgemäßen Nutzung umfasst sind insbesondere:
            </p>
            <ul>
              <p>a) Die Nutzung für rechtswidrige Zwecke, einschließlich, aber nicht beschränkt auf Strafgesetze,
                Gesetze über geistiges Eigentum, Datenschutz und Privatsphäre; </p>
              <p>b) Die Nutzung von Informationen oder Materialien, die Patente, Marken, Geschäftsgeheimnisse,
                Urheberrechte oder andere geschützte oder geistige Eigentumsrechte verletzen;</p>
              <p>c) Identitätsdiebstahl und/oder unbefugter Zugriff auf die Konten anderer Nutzer</p>
              <p>d) Das Umgehen, Entfernen, Beschädigen oder anderweitige Stören von Sicherheitsmechanismen,
                insbesondere Funktionen, die die Nutzung oder das Kopieren von Inhalten, die über die Plattform
                zugänglich sind, verhindern oder einschränken,</p>
              <p>e) Die Störung des Betriebs der Plattform, insbesondere der Nutzung des Dienstes durch einen Dritten,
                etwa durch das Hochladen oder anderweitige Verbreiten von Viren, Würmern oder anderem bösartigen
                Code;</p>
              <p>f) (Versuchtes) Hacking und/oder Passwortmining und/oder (versuchte) andere Mittel, um unbefugten
                Zugriff auf die Plattform oder einen Teil davon, andere Konten, Computersysteme oder Netzwerke, die mit
                dem Dienst verbunden sind, oder einen Teil davon zu verschaffen oder das ordnungsgemäße Funktionieren
                des Dienstes oder die mit dem Dienst durchgeführten Aktivitäten zu stören;</p>
              <p>g) Die Verwendung von Bots, Crawlern und/oder anderen automatisierten Mitteln, um unbefugt auf die
                Plattform zuzugreifen oder Sicherheitsmaßnahmen zu umgehen;</p>
              <p>h) Der Verkauf oder die Übertragung der Möglichkeit des Zugriffs auf Ihr Profil oder Konto an bzw. auf
                eine andere Person</p>
            </ul>
          </ul>
          <h3>4. Datenpflege, Datensicherheit und Umgang mit Zugangsdaten</h3>
          <ul>
            <p>4.1 Der Nutzer stellt sicher, dass sämtliche Informationen, die er bei Registrierung und im Verlauf der
              Nutzung der Plattform zur Verfügung stellen, zutreffend, genau, aktuell und vollständig sind. Es liegt in
              der Verantwortung des Nutzers, dafür zu sorgen, dass die gespeicherten Daten stets korrekt sind.</p>
            <p>4.2 Der Nutzer hat alles Zumutbare zu tun, um einen störungsfreien Betrieb der Plattform zu ermöglichen
              und alles zu unterlassen, was die Sicherheit und Stabilität der Plattform und/oder der über die Plattform
              erbrachten Dienste beeinträchtigen könnte.<br /><br />Insbesondere hat der Nutzer die Zugangsdaten geheim
              zu
              halten und dafür Sorge zu tragen, dass kein Dritter diese Daten missbrauchen kann.</p>
            <p>4.3 Der Nutzer wird die Geräte, über die er auf die Plattform zugreift, vor unberechtigtem Zugriff
              sichern und regelmäßig auf Viren und andere Schadsoftware überprüfen. Sollte sich ein Verdacht auf
              Virenbefall oder dergleichen ergeben, dürfen die betroffenen Geräte nicht für den Zugriff auf die
              Plattform benutzt werden. </p>
          </ul>
          <h3>5. Unterrichtung über Störungen</h3>
          <ul>
            <p>5.1 Der Nutzer wird Fehler, die bei der Inanspruchnahme der Plattform bemerkt werden, unverzüglich
              melden, soweit ihm dies ohne eine Beeinträchtigung seines gewöhnlichen Geschäftsbetriebs zumutbar ist.</p>
            <p>5.2 Bei Verdacht des Missbrauchs von Zugangsdaten wird der Nutzer dies unverzüglich der VW Bank
              mitteilen.</p>
          </ul>

          <h3>6. Änderungen </h3>
          <ul>
            <p>6.1 Die VW Bank kann diese Nutzungsvereinbarung einschließlich ihrer Anlagen ändern, soweit dies dem
              Nutzer zumutbar ist. Diese Änderungsmöglichkeit besteht nicht, wenn die vorgesehene Änderung eine Änderung
              von Ziffer 2.1 (Unentgeltlichkeit) vorsieht oder bewirkt oder die bestehenden Hauptpflichten der VW Bank
              zum Nachteil des Nutzers abändert. In diesen Fällen wird die VW Bank dem Nutzer ein Änderungsangebot
              unterbreiten.</p>
            <p>6.2 Die VW Bank teilt dem Nutzer die Änderungen dieser Nutzungsvereinbarung einschließlich der Anlagen
              spätestens zwei Monate vor dem Zeitpunkt ihres Wirksamwerdens mit. Die VW Bank kommuniziert die Änderungen
              über die Plattform; außerdem weist sie den Nutzer über die hinterlegte E-Mail-Adresse auf die Änderungen
              hin.</p>
            <p>6.3 Die Zustimmung des Nutzers gilt als erteilt, wenn er seine Ablehnung nicht vor dem vorgeschlagenen
              Zeitpunkt des Wirksamwerdens der Änderungen angezeigt hat. Auf diese Genehmigungswirkung wird ihn die VW
              Bank in ihrer Kommunikation besonders hinweisen.</p>
            <p>6.4 Der Nutzer kann die Nutzung des Kundenportals vor dem vorgeschlagenen Zeitpunkt des Wirksamwerdens
              der Änderungen auch kündigen. Auf dieses Kündigungsrecht wird er besonders hingewiesen.</p>
          </ul>

          <h3>7. Kommunikation </h3>
          <ul>
            <p>Die Kommunikation zwischen der VW Bank und dem Nutzer im Zusammenhang mit der Nutzung der Plattform
              erfolgt
              grundsätzlich auf elektronischem Wege über die Kommunikationsfunktionen der Plattform sowie ergänzend über
              die vom Nutzer angegebene E-Mail-Adresse.</p>
          </ul>
          <h3>8. Übertragung auf Dritte, Subunternehmer</h3>
          <ul>
            <p>Die VW Bank hat das Recht, diesen Vertrag sowie einzelne Rechte und Pflichten aus diesem Vertrag auf
              Dritte zu übertragen und Subunternehmer mit dem Betrieb der Plattform und/oder der Erbringung sonstiger
              Vertragsleistungen zu beauftragen.</p>
          </ul>
          <h3>9. Vertragslaufzeit und Kündigung</h3>
          <ul>
            <p>9.1 Dieser Vertrag läuft ab der Registrierung des Nutzers auf unbestimmte Zeit.</p>
            <p>9.2 Die VW Bank kann den Vertrag jederzeit mit einer Frist von 30 Tagen ordentlich kündigen. Der Nutzer
              kann den Vertrag jederzeit ohne Einhaltung einer Frist kündigen. Die VW Bank kann die Kündigung unter
              anderem dadurch erklären, dass sie die Zurverfügungstellung einzelner Dienste oder der Plattform insgesamt
              einstellt. Der Nutzer kann die Kündigung dadurch erklären, dass er sein Profil löscht.</p>
            <p>9.3 Wenn die VW Bank den begründeten Verdacht hat, dass Sie gegen diese Nutzungsbedingungen verstoßen,
              behält sich die VW Bank das Recht vor, Ihren Zugriff auf die Dienste ohne Vorankündigung zu
              deaktivieren.</p>
            <p>9.4 Das Recht zur außerordentlichen Kündigung bleibt unberührt. </p>
            <p>9.5 Eine Kündigung bedarf der Textform.<br /><br />Rechtsverhältnisse der Parteien im
              Zusammenhang mit einem über die Plattform abgeschlossenen Finanzierungsvertrag bleiben von
              einer Beendigung der Plattformnutzung unberührt.</p>
          </ul>
          <h3>10. Haftung</h3>
          <ul>
            <p>10.1 Unbeschränkte Haftung: Die VW Bank haftet unbeschränkt für Vorsatz und grobe Fahrlässigkeit und nach
              Maßgabe des Produkthaftungsgesetzes sowie bei arglistigem Verschweigen eines Mangels oder der Übernahme
              einer Garantie. Für leichte Fahrlässigkeit haftet die VW Bank unbeschränkt bei Schäden aus der Verletzung
              des Lebens, des Körpers oder der Gesundheit von Personen.</p>
            <p>10.2 Beschränkte Haftung: Hinsichtlich der Bereitstellung der Plattform haftet die VW Bank bei leichter
              Fahrlässigkeit im Übrigen nur bei der Verletzung einer wesentlichen Vertragspflicht, deren Erfüllung die
              ordnungsgemäße Durchführung des Vertrags überhaupt erst ermöglicht und auf deren Einhaltung der Nutzer
              regelmäßig vertrauen darf (Kardinalpflicht). In diesen Fällen ist die Haftung der Höhe nach auf den
              vertragstypischen, vorhersehbaren Schaden begrenzt. Diese Haftungsbeschränkung gilt auch zu Gunsten der
              Erfüllungsgehilfen der VW Bank.</p>
            <p>10.3 Im Übrigen ist die Haftung ausgeschlossen.</p>
            <p>10.4 Eine Schadensersatzpflicht kann nur innerhalb einer Ausschlussfrist von 12 Monaten nach
              Kenntnis über den Schaden geltend gemacht werden. Dies gilt nicht für Ansprüche nach Ziffer
              10.1 (Unbeschränkte Haftung).</p>
            <p>10.5 Da eine ununterbrochene Verfügbarkeit der Plattform schon nicht Gegenstand der geschuldeten Leistung
              ist (siehe Ziffer 2.6.), haftet die VW Bank nicht für etwaige Schäden, die durch den Ausfall der der
              Plattform hervorgerufen werden, insbesondere sofern der jeweilige Ausfall auf technische Wartungsarbeiten,
              Migration von Daten, Updates oder sonstige gleichgelagerte Umstände zurückzuführen ist. </p>
          </ul>
          <h3>11. Freistellung</h3>
          <ul>
            <p>Der Nutzer verpflichtet sich, die VW Bank von sämtlichen Ansprüchen und Schäden freizustellen, die sich
              aus oder im Zusammenhang mit Ansprüchen Dritter aufgrund eines Verstoßes des Nutzers gegen diese
              Nutzungsbedingungen ergeben.</p>
          </ul>
          <h3>12. Datenschutz</h3>
          <ul>
            <p>Über die Erhebung und Verarbeitung Ihrer personenbezogenen Daten im Zusammenhang mit der
              Nutzung der Plattform informieren wir Sie in unseren <a [href]="DATA_PRIVACY_URL"
                target="_blank"><u>Datenschutzinformationen</u></a>.</p>
          </ul>
          <h3>13. Geheimhaltung</h3>
          <ul>
            <p>13.1 Die Parteien werden sämtliche Informationen, Dokumente und
              Daten, über die sie im Zusammenhang mit der Nutzung der Plattform Kenntnis erlangen, vertraulich
              behandeln, die als vertraulich gekennzeichnet sind oder im geschäftlichen Verkehr allgemein als
              vertraulich gelten („Vertrauliche Informationen“) und sämtliche angemessenen und zumutbaren
              Sicherheitsmaßnahmen treffen. Sämtliche Vertrauliche Informationen, die von einer Partei an die andere
              übermittelt werden, dürfen von der anderen Partei nur für die vertraglich vorausgesetzten Zwecke verwendet
              werden und die empfangende Partei darf die Vertraulichen Informationen nicht ohne Zustimmung der anderen
              Partei gegenüber Dritten offenbaren, es sei denn</p>
            <ul>
              <p>(I) zur Erfüllung der vertraglichen Zwecke,</p>
              <p>(II) gegenüber externen Beratern, wenn diese zur Geheimhaltung verpflichtet sind, </p>
              <p>(III) zur Durchsetzung rechtlicher Ansprüche gegen die andere Partei oder </p>
              <p>(IV) zur Erfüllung gesetzlicher oder behördlicher Pflichten.</p>
            </ul>
            <p>Als Dritte gelten nicht die Volkswagen Financial Services AG, Braunschweig, oder Volkswagen
              Bank GmbH, Braunschweig, sowie alle gem. §§ 15 ff AktG mit den vorstehenden Gesellschaften
              verbundenen Unternehmen.</p>
            <p>13.2 Die Verpflichtung zur Vertraulichkeit bezieht sich nicht auf Informationen,</p>

            <ul>
              <p>(I) die öffentlich bekannt waren oder ohne Verschulden einer Partei nachträglich
                öffentlich bekannt werden;</p>
              <p>(II) die der anderen Partei bereits bei Vertragsschluss bekannt waren und keiner
                Geheimhaltungsvereinbarung unterliegen und</p>
              <p>(III) die der Partei durch einen Dritten offenbart wurden und keiner
                Geheimhaltungsvereinbarung unterliegen.</p>
            </ul>
            <p>13.3 Die Vertraulichkeitsverpflichtungen gelten auch im Falle einer Beendigung dieses
              Vertrages unbefristet fort. Die Parteien werden auf Verlangen der jeweils anderen Partei bei
              Beendigung dieses Vertrages vertrauliche Informationen der jeweils anderen Partei
              zurückgeben oder im Rahmen des technisch Möglichen vernichten.</p>
          </ul>
          <h3>14. Sonstiges</h3>
          <ul>
            <p>14.1 Sollten diese Nutzungsbedingungen unwirksame Regelungen enthalten oder lückenhaft sein,
              bleibt die Wirksamkeit der Regelungen im Übrigen unberührt. Die Parteien verpflichten sich
              in diesem Fall, die unwirksame oder undurchführbare Bestimmung durch eine wirksame oder
              durchführbare zu ersetzen, die dem Vertragszweck am nächsten kommt. Entsprechendes gilt im
              Falle einer Vertragslücke.</p>
            <p>14.2 Es gilt deutsches Recht unter Ausschluss der Bestimmungen des UN-Kaufrechts (CISG).
              Ausschließlicher Gerichtsstand für alle aus oder im Zusammenhang mit diesem Vertrag
              entstehenden Streitigkeiten ist Braunschweig.</p>
            <p>14.3 Änderungen und/oder Ergänzungen dieses Vertrages bedürfen der Schriftform.</p>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>