import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BackendService} from '@app/core';
import {AnnuitiesResponse} from '@app/core/backend/response/annuities.response';
import {ErrorType} from '@app/util/model/error-type';
import {PaymentPlan} from '@app/util/model/payment-plan';
import {DateUtil} from '@app/util/static-services/date.util';
import {environment} from '@env/environment';
import {CookieService} from 'ngx-cookie-service';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Terms} from '@app/util/model/terms';

@Component({
  templateUrl: './overlay-calculator.component.html',
  styleUrls: ['./overlay-calculator.component.css']
})
export class OverlayCalculatorComponent implements OnInit, OnDestroy {

  public readonly BRAND_NAME: string = environment.brandName;

  amount: number;

  apiKey: string;

  domain = environment.domain;

  desiredDuration: number;

  loaded = false;

  annuities$: Observable<AnnuitiesResponse>;

  isCheckout: boolean;

  checkoutCallback: boolean;

  invalidAmount = false;

  terms: Terms;

  interestFreeRatesMerchant: number;

  constructor(private route: ActivatedRoute,
              private backendService: BackendService,
              private cookieService: CookieService) {
    document.body.classList.add('bg-transparent');

    this.route.paramMap.subscribe(next => {
      this.amount = +next.get('amount');
      this.desiredDuration = +next.get('desiredDuration') || 60;
      this.apiKey = next.get('apiKey');
      this.interestFreeRatesMerchant = +next.get('interestFreeRatesMerchant');
      this.isCheckout = next.get('isCheckout') === 'true';
      this.checkoutCallback = next.get('checkoutCallback') === 'true';
    });
  }

  ngOnInit(): void {
    this.annuities$ = this.backendService
      .getAnnuitiesForAmount(this.amount, this.apiKey, this.interestFreeRatesMerchant)
      .pipe(
        catchError(error => {
          if (error.resultCode && error.resultCode === ErrorType.INVALID_AMOUNT) {
            this.invalidAmount = true;
          }
          throw error;
        })
      );
  }

  closeFrame(toCheckout: boolean, plan?: PaymentPlan) {
    window.parent.postMessage({
      'function': 'c2CloseWizard',
      'purchased': toCheckout,
      'chosenPlan': plan
    }, '*');
    window.dispatchEvent(new Event('wizardHidden'));
  }

  ngOnDestroy(): void {
    document.body.classList.remove('bg-transparent');
  }

  closeOverlay(event: any) {
    if (event.target && event.target.classList.contains('c2-overlay')) {
      this.closeFrame(false);
    }
  }

  setCurrentPlan(event: PaymentPlan) {
    this.cookieService.set('desiredDuration', event.duration.toString(), DateUtil.addToDate(undefined, 1000 * 60 * 60),
      '/', this.domain, this.domain !== 'localhost');
    this.closeFrame(false, event);
  }
}
