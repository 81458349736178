<div class="container">
  <div class="row">
    <div class="col-12 mb-5">
      <nav-logo></nav-logo>
    </div>
    <div class="col-12 col-md-8 offset-md-2">
      <div class="card shadow-sm">
        <div class="card-body">
          <h1>Anlage Datenschutzinformation</h1>
          <ul>
            <li><a routerLink="/data-privacy/agreement"><u>Datenschutzinformationen</u></a></li>
            <li><a routerLink="/data-privacy/notice"><u>Datenschutzinformation für die Kundenwebseite</u></a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>