import {Injectable} from '@angular/core';
import {Tracking} from '@app/shared/tracking/model/tracking';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {

  constructor() {
    this.setupTracking();
  }

  private setupTracking() {
    (window as any).tracking = {
      page: {
        brand: "VWFS",
        country: "DE",
        language: "de",
        platform: "Financing"
      },
      core: {
        category: {
          primaryCategory: "Financing",
          secondaryCategory: "ClassicCredit",
          inventoryType: "Asset-based products",
          maturityLevel: "Online contract",
          contractType: "New"
        },
        attributes: {
          brand: "VWFS"
        }
      },
      product: [
        {
          category: 'Finance',
          name: 'ClassicCredit',
          attributes: {
            typeOfSale: 'Hybrid sales',
            currency: 'EUR'
          }
        }
      ]
    } as Tracking;
  }

}
