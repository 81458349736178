<div class="container">
  <div class="row mt-3 mt-md-5 text-center">
    <div class="col-12 col-sm-6 offset-sm-3 col-md-4 offset-md-4">
      <div class="mt-3 mt-md-5 text-center">
        <div class="logo"></div>
        <div *ngIf="loading">
          <br>
          <hr>
          <br>
          <p class="text-uppercase">Volkswagen Bank GmbH</p>
          <small class="text-uppercase text-info">The Key To Mobility</small>
        </div>
      </div>

      <div class="mt-5" *ngIf="loading">
        <img class="img-responsive  rotate-center" src="assets/icons/spinner.svg" width="80"
             alt="loading animation">
        <h2 class="mt-5">Ihr Einkauf wird geladen ...</h2>
      </div>

      <div class="mt-3" *ngIf="!loading && !hasError">
        <div class="text-center" [ngSwitch]="basePurchaseInfo.purchaseState"
             *ngIf="!basePurchaseInfo.pendingPurchase">
          <div *ngSwitchCase="'PENDING'">
            <i class="material-icons text-primary md-48">videocam</i>
            <h3 class="text-uppercase py-3">Einloggen und abschließen</h3>
            <p class="text-info">
              Loggen Sie sich ein um Ihren Einkauf mit {{ BRAND_NAME }} abzuschliessen.
            </p>
          </div>
          <div *ngSwitchCase="'VERIFY'">
            <i class="material-icons text-primary md-48">videocam</i>
            <h3 class="text-uppercase py-3">Einloggen und abschließen</h3>
            <p class="text-info">
              Loggen Sie sich ein um Ihren Einkauf mit {{ BRAND_NAME }} abzuschliessen.
            </p>
          </div>
          <div *ngSwitchCase="'PENDING_FULFILLMENT'">
            <i class="material-icons text-primary md-48">exit_to_app</i>
            <h3 class="text-uppercase py-3">Sie können Ihren Einkauf abholen</h3>
            <p class="text-info">
              Sie haben Ihren Vertrag unterschrieben. Wir haben Ihnen eine SMS mit Ihrem Abholcode gesendet.
            </p>
          </div>
          <div *ngSwitchCase="'PAID'">
            <i class="material-icons text-primary md-48">check</i>
            <h3 class="text-uppercase py-3">Einkauf bezahlt</h3>
            <p class="text-info">
              Sie haben Ihren Einkauf mit {{ BRAND_NAME }} bezahlt. Der Händler wurde darüber bereits informiert.
            </p>
          </div>
          <div *ngSwitchCase="'PICKED_UP'">
            <i class="material-icons text-primary md-48">check</i>
            <h3 class="text-uppercase py-3">Einkauf bezahlt und abgeholt</h3>
            <p class="text-info">
              Sie haben einen Einkauf mit {{ BRAND_NAME }} bezahlt und abgeholt. Weitere Informationen erhalten Sie im
              {{ BRAND_NAME }}
              Portal.
            </p>
          </div>
          <div *ngSwitchCase="'CANCELLED'">
            <i class="material-icons text-warning md-48">warning</i>
            <h3 class="text-uppercase py-3">Einkauf ungültig</h3>
            <p class="text-info">
              Sie können diesen Einkauf nicht mehr abschließen.
            </p>
          </div>
          <div *ngSwitchCase="'TIMEOUT'">
            <i class="material-icons text-warning md-48">warning</i>
            <h3 class="text-uppercase py-3">Einkauf ungültig</h3>
            <p class="text-info">
              Sie können diesen Einkauf nicht mehr abschließen.
            </p>
          </div>
        </div>
        <div *ngIf="basePurchaseInfo.pendingPurchase">
          <i class="material-icons text-primary md-48">shopping_cart</i>
          <h3 class="text-uppercase py-3">Sie haben bereits einen Einkauf</h3>
          <p class="text-info">
            Sie können mit {{ BRAND_NAME }} nur einen Einkauf auf Raten bezahlen. Loggen Sie sich ein und wählen Sie
            einen Einkauf aus,
            den Sie
            bezahlen möchten.
          </p>
        </div>

        <button mat-raised-button color="accent" (click)="goToLogin()" class="mt-5 px-5" *ngIf="showLoginLink()"
        >
          Login
        </button>
      </div>
      <div class="mt-3" *ngIf="hasError">
        <img class="img-responsive c2-anim-loader" src="assets/icons/nok.svg" width="80" alt="loading animation">
        <div
            *ngIf="resultCode && (resultCode === 400 || resultCode === 100 || resultCode === 501 || resultCode === 230 || resultCode === 900)">
          <h2 class="mt-5">Ein Fehler ist aufgetreten</h2>
          <p>Es konnte leider kein Einkauf gefunden werden.</p>
        </div>
      </div>
    </div>
  </div>
</div>


