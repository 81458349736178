import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ExampleCalculatorComponent} from '@app/marketing/example-calculator/example-calculator.component';
import {ImpressumComponent} from '@app/marketing/impressum/impressum.component';
import {OverlayCalculatorComponent} from '@app/marketing/overlay-calculator/overlay-calculator.component';
import {DataPrivacyHomepageComponent} from '@app/marketing/tac/data-privacy-homepage/data-privacy-homepage.component';
import {DataPrivacyComponent} from '@app/marketing/tac/data-privacy/data-privacy.component';
import {TacComponent} from '@app/marketing/tac/tac.component';
import {ConciliationBoardComponent} from '@app/marketing/tac/conciliation-board/conciliation-board.component';
import {ComplaintHandlingComponent} from '@app/marketing/tac/complaint-handling/complaint-handling.component';
import {ErrorComponent} from '@app/shared/error/error.component';
import {NotFoundComponent} from '@app/shared/not-found/not-found.component';
import {NotReachableComponent} from '@app/shared/not-reachable/not-reachable.component';
import {SimulationComponent} from '@app/simulation/simulation.component';
import {PurchaseLoadComponent} from './purchase-load.component';
import {TermsOfUseComponent} from '@app/marketing/tac/terms-of-use/terms-of-use.component';
import {ExternalInlineCalculatorComponent} from '@app/marketing/external-inline-calculator/external-inline-calculator.component';
import {RedirectGuard} from '@app/shared/guards/redirect-guard.service';

const routes: Routes = [
  {
    path: 'onboarding',
    loadChildren: () => import('./onboarding/onboarding.module').then(m => m.OnboardingModule)
  },
  {
    path: 'pending',
    loadChildren: () => import('./pending/pending.module').then(m => m.PendingModule)
  },
  {
    path: 'portal',
    loadChildren: () => import('./portal/portal.module').then(m => m.PortalModule)
  },
  {
    path: 'data-privacy',
    component: TacComponent
  },
  {
    path: 'data-privacy/notice',
    component: DataPrivacyHomepageComponent,
    canActivate: [RedirectGuard],
    data: {
      externalUrl: 'https://www.vwfs.de/metaseiten/datenschutz.html',
      productType: 'SOFORTKREDIT'
    }
  },
  {
    path: 'data-privacy/agreement',
    component: DataPrivacyComponent
  },
  {
    path: 'terms-of-use',
    component: TermsOfUseComponent
  },
  {
    path: 'conciliation-board',
    component: ConciliationBoardComponent
  },
  {
    path: 'complaint-handling',
    component: ComplaintHandlingComponent
  },
  {
    path: 'example-calculator/:apiKey',
    component: ExampleCalculatorComponent
  },
  {
    path: 'inline-calculator',
    component: ExternalInlineCalculatorComponent
  },
  {
    path: 'inline-calculator/:apiKey',
    component: ExternalInlineCalculatorComponent
  },
  {
    path: ':sc/:key',
    component: PurchaseLoadComponent
  },
  {
    path: 'legal-notice',
    component: ImpressumComponent
  },
  {
    path: 'simulation/:sc/:key',
    component: SimulationComponent
  },
  {
    path: 'overlay-calculator/:apiKey/:interestFreeRatesMerchant/:isCheckout/:amount',
    component: OverlayCalculatorComponent
  },
  {
    path: 'overlay-calculator/:apiKey/:interestFreeRatesMerchant/:isCheckout/:amount/:desiredDuration',
    component: OverlayCalculatorComponent
  },
  {
    path: 'overlay-calculator/:apiKey/:interestFreeRatesMerchant/:isCheckout/:amount/:desiredDuration/:checkoutCallback',
    component: OverlayCalculatorComponent
  },
  {
    path: 'error',
    component: ErrorComponent
  },
  {
    path: 'not-reachable',
    component: NotReachableComponent
  },
  {
    path: '',
    redirectTo: 'portal',
    pathMatch: 'full'
  },
  {
    path: '**',
    component: NotFoundComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    anchorScrolling: 'enabled',
    useHash: true
})],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
