<div class="container">
  <div class="row">
    <div class="col-12 mb-5">
      <nav-logo></nav-logo>
    </div>
    <div class="col-12 col-md-8 offset-md-2">
      <div class="card shadow-sm">
        <div class="card-body">
          <h1 class="pb-2">Anlage Datenschutzinformationen</h1>
          <h3 class="pb-4"><u>Datenschutzinformationen der Volkswagen Bank GmbH</u></h3>
          <p>
            Im Rahmen dieser Geschäftsbeziehung verarbeitet der Verantwortliche personenbezogene Daten von Ihnen und
            speichert sie für die Dauer, die zur Erfüllung der festgelegten Zwecke
            und gesetzlicher Verpflichtungen erforderlich ist. Im Folgenden informieren wir Sie darüber, um welche Daten
            es sich dabei handelt, auf welche Weise sie verarbeitet werden und
            welche Rechte Ihnen diesbezüglich zustehen. Daten, die als Pflichtangaben gekennzeichnet sind, sind entweder
            gesetzlich oder vertraglich vorgeschrieben oder für den Vertragsschluss
            erforderlich. Die Nichtbereitstellung der abgefragten Daten kann für Sie rechtliche oder wirtschaftliche
            Nachteile haben. So kann es z.B. zu einer Ablehnung des Vertragsschlusses oder
            zu schlechteren Vertragskonditionen kommen.
          </p>

          <h3>Verantwortlicher</h3>
          <p>
            Volkswagen Bank GmbH
            <br>
            Gifhorner Straße 57
            <br>
            38112 Braunschweig
            <br>
            betroffenenrechte&#64;volkswagenbank.de
          </p>

          <h3>Allgemeines zur Datenverarbeitung</h3>
          <p>
            Der Verantwortliche verarbeitet Ihre Daten aus dieser Geschäftsbeziehung (z. B. aus dem Antrag und der
            Vertragsabwicklung) (im Folgenden: „Ihre Daten“). Hierbei werden Ihre Daten
            zum Zweck der Antragsprüfung, Vorgangsanlage, Vertragsabwicklung, Kundenberatung und Auswertung verarbeitet.
            Ihre Daten werden mit Auftragsverarbeitern und anderen Auftragnehmern (z. B. aus den Branchen: Logistik,
            Telekommunikation, Forderungsmanagement, Marketing, Druck, Markt-
            und Meinungsforschung) ausgetauscht. Zudem tauscht der Verantwortliche Ihre Daten mit den Gesellschaften der
            Volkswagen Finanzdienstleistungsgruppe (z. B. Unternehmen
            aus den Branchen: Bank, Leasing, Versicherung, Mobilität und Tank-/Servicekarten - im Folgenden: „VW
            Finanzdienstleistungsgruppe“) aus; für diese Verarbeitung besteht zum Teil
            eine gemeinsame Verantwortlichkeit (nähere Informationen hierzu sind
            abrufbar unter <a href="www.vwfs.de/datenschutzinformationen"
              target="_blank>"><u>www.vwfs.de/datenschutzinformationen</u></a>). Ebenso erfolgt ein Austausch mit
            öffentlichen Stellen, öffentlich zugänglichen Quellen und ggf. mit Versicherern, Kreditinstituten,
            Zahlungsdienstleistern und Kooperationspartnern. Die Verarbeitung sowie der Austausch Ihrer Daten zu den
            oben genannten Zwecken findet insbesondere statt, soweit
          </p>
          <ul>
            <li>
              dies für die Erfüllung eines Vertrages, den Sie geschlossen haben, oder zur Durchführung vorvertraglicher
              Maßnahmen erforderlich ist (Art. 6 Abs. 1 S. 1 lit. b Datenschutz-Grundverordnung, DSGVO). Die
              Datenverarbeitung ist insbesondere erforderlich, um die Vollständigkeit und
              Richtigkeit der Daten sowie deren Digitalisierung zu gewährleisten und um den
              Vertrag durchzuführen;
            </li>
            <li>
              dies zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist (Art. 6 Abs. 1 S. 1 lit. c DSGVO). Die
              Datenverarbeitung ist insbesondere erforderlich für die Gewährleistung der Vollständigkeit und Richtigkeit
              von Steuerdaten nach der Abgabenordnung, Gewerbeordnung und nach dem
              Handelsgesetzbuch. Darüber hinaus ist die Datenverarbeitung erforderlich
              für die Sicherung des Wirtschaftsverkehrs oder Kapitalmarktes (z.B. nach dem Kreditwesengesetz);
            </li>
            <li>
              dies zur Wahrung berechtigter Interessen des Verantwortlichen oder eines Dritten erforderlich ist (Art. 6
              Abs. 1 S. 1 lit. f DSGVO). Die Datenverarbeitung ist insbesondere erforderlich,
              um informierte Entscheidungen der Beteiligten auch in Ihrem Interesse zu gewährleisten und zu optimieren
              und um eine dauerhaft hohe Qualität und Einheitlichkeit der Kundenberatung durch den Verantwortlichen und
              die VW Finanzdienstleistungsgruppe zu gewährleisten. Darüber hinaus
              ist die Datenverarbeitung erforderlich zum Schutz von Vermögenswerten
              des Verantwortlichen, der VW Finanzdienstleistungsgruppe oder ihrer Kunden sowie zur Erfüllung
              konzerninterner Verwaltungs- und Abrechnungszwecke. Weiter ist die Datenverarbeitung erforderlich, um die
              Interessen und Präferenzen der Kunden zu analysieren und zu bewerten und
              daraus allgemeine Auswertungen für interne Zwecke zu erstellen (z. B.
              zum betriebswirtschaftlichen Monitoring und zur Optimierung der Produkte);
            </li>
            <li>
              dies von Ihrer freiwillig erteilten Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO) umfasst ist;
            </li>
            <li>
              dies von Ihrer freiwillig erteilten Einwilligung (Art. 9 Abs. 2 lit. a DSGVO) oder einer sonstigen
              Rechtsgrundlage (Art. 9 Abs. 2 lit. b bis j DSGVO) umfasst ist, wenn Ihre Daten auch
              besondere Kategorien personenbezogener Daten gemäß Art. 9 Abs. 1 DSGVO (z. B. Gesundheitsdaten) enthalten.
            </li>
          </ul>

          <h3>Drittlandübermittlung</h3>
          <p>
            Der Verantwortliche kann Ihre Daten auch an ein Land außerhalb des Europäischen Wirtschaftsraumes (EWR)
            übermitteln. Diese Übermittlung erfolgt hierbei unter Einhaltung der
            besonderen Voraussetzungen der Art. 44 - 49 DSGVO, wobei das angemessene Schutzniveau insbesondere entweder
            durch einen Angemessenheitsbeschluss der Europäischen
            Kommission gemäß Art. 45 DSGVO, abgeschlossene EU-Standardvertragsklauseln gemäß Art. 46 Abs. 2 lit. c und d
            DSGVO oder verbindliche interne Datenschutzvorschriften gemäß
            Art. 47 DSGVO gewährleistet wird. Die EU-Standardvertragsklauseln können Sie auf der Website der
            Europäischen Kommission abrufen und einsehen oder direkt beim Verantwortlichen
            erfragen und in Kopie erhalten.
          </p>

          <h3>Allgemeine Speicherfristen</h3>
          <p>
            Sollten Sie sich zu Produkten/Dienstleistungen des Verantwortlichen informiert, aber keinen Vertrag
            angebahnt haben, werden Ihre Daten längstens für 6 Monate nach dem letztmaligen
            Kontakt zwischen Ihnen und dem Verantwortlichen gespeichert.
            <br>
            Im Übrigen beginnt die Speicherfrist am Ende des Kalenderjahres des letzten Ereignisses im Rahmen dieser
            Geschäftsbeziehung:
          </p>
          <ul>
            <li>
              Wenn es zu einer Vertragsanbahnung, aber zu keinem Vertragsschluss gekommen ist, werden Ihre Daten
              längstens für 3 Jahre gespeichert.
            </li>
            <li>
              Wenn es zu einem Vertragsschluss gekommen ist, werden Ihre Daten, insbesondere steuerrechtlich relevante
              Daten, bis zum Ablauf der gesetzlichen Aufbewahrungsfristen, längstens
              für 10 Jahre nach Beendigung des Vertrages, gespeichert. Die allgemeine Speicherdauer von Ihren Daten kann
              ausnahmsweise bis zu 30 Jahre betragen, soweit dies zur Geltendmachung, Ausübung oder Verteidigung von
              Rechtsansprüchen erforderlich ist.
            </li>
          </ul>
          <p>
            Auf abweichende Speicherfristen bei einzelnen Datenverarbeitungen weisen wir Sie innerhalb dieser
            Datenschutzinformationen am Ende des jeweiligen Abschnitts hin. Die Speicherfristen können sich
            ausnahmsweise verlängern, wenn die weitere Speicherung rechtlich zulässig (z. B. zum
            Schutz von Vermögenswerten oder zur Wahrung und ggf. Durchsetzung
            unserer Rechte) oder erforderlich ist (z. B. Aufbewahrungsfrist für Werbeeinwilligungen oder im Rahmen von
            Betriebsprüfungen). </p>

          <h3>Bonitätsprüfung</h3>
          <p>
            Der Verantwortliche verarbeitet Ihre Daten zur Bonitätsprüfung und tauscht Ihre Daten mit Auskunfteien, den
            Gesellschaften der VW Finanzdienstleistungsgruppe und ggf. Zahlungsdienstleistern aus, soweit</p>
          <ul>
            <li>
              dies für die Erfüllung eines Vertrages, den Sie geschlossen haben, oder zur Durchführung vorvertraglicher
              Maßnahmen erforderlich ist (Art. 6 Abs. 1 S. 1 lit. b DSGVO). Die Datenverarbeitung ist insbesondere
              erforderlich, um den Antrag zu prüfen und den Vertrag durchzuführen;
            </li>
            <li>
              die Datenübermittlung zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist (Art. 6 Abs. 1 S. 1
              lit. c DSGVO). Die Datenverarbeitung ist insbesondere erforderlich für die Sicherung
              des Wirtschaftsverkehrs oder Kapitalmarktes (z. B. nach dem Kreditwesengesetz);
            </li>
            <li>
              dies zur Wahrung berechtigter Interessen des Verantwortlichen oder eines Dritten erforderlich ist (Art. 6
              Abs. 1 S. 1 lit. f DSGVO). Die Datenverarbeitung ist insbesondere erforderlich
              zum Schutz von Vermögenswerten des Verantwortlichen, der VW Finanzdienstleistungsgruppe oder ihrer Kunden.
            </li>
          </ul>

          <div class="border p-2 m-3">
            <h3>Datenübermittlung an die SCHUFA und Befreiung vom Bankgeheimnis</h3>
            <p>
              Der Verantwortliche übermittelt im Rahmen dieses Vertragsverhältnisses erhobene personenbezogene Daten
              über die Beantragung, die Durchführung und Beendigung dieser
              Geschäftsbeziehung sowie Daten über nicht vertragsgemäßes Verhalten oder betrügerisches Verhalten an die
              SCHUFA Holding AG, Kormoranweg 5, 65201 Wiesbaden.
            </p>
            <p>
              Rechtsgrundlagen dieser Übermittlungen sind Artikel 6 Absatz 1 lit. b und Artikel 6 Absatz 1 lit. f der
              Datenschutz-Grundverordnung (DS-GVO).
            </p>
            <p>Übermittlungen auf der Grundlage von Artikel 6 Absatz 1 lit. f DSGVO dürfen nur erfolgen, soweit dies zur
              Wahrung berechtigter Interessen der Bank/Sparkasse oder Dritter erforderlich
              ist und nicht die Interessen oder Grundrechte und Grundfreiheiten der betroffenen Person, die den Schutz
              personenbezogener Daten erfordern, überwiegen.
            </p>
            <p>Der Datenaustausch mit der SCHUFA dient auch der Erfüllung gesetzlicher Pflichten zur Durchführung von
              Kreditwürdigkeitsprüfungen von Kunden (§ 505a des Bürgerlichen Gesetzbuches, § 18a des
              Kreditwesengesetzes). Der Kunde befreit den Verantwortlichen insoweit auch vom
              Bankgeheimnis. Die SCHUFA verarbeitet die erhaltenen Daten und verwendet
              sie auch zum Zwecke der Profilbildung (Scoring), um ihren Vertragspartnern im Europäischen Wirtschaftsraum
              und in der Schweiz sowie ggf. weiteren Drittländern (sofern zu diesen
              ein Angemessenheitsbeschluss der Europäischen Kommission besteht oder Standardvertragsklauseln vereinbart
              wurden, die unter <a href="www.schufa.de" target="_blank>">www.schufa.de</a>
              eingesehen werden können) Informationen unter anderem zur Beurteilung der Kreditwürdigkeit von natürlichen
              Personen zu geben. Nähere
              Informationen zur Tätigkeit der SCHUFA können dem SCHUFA-Informationsblatt nach Art. 14 DSGVO entnommen
              oder online unter <a href="www.schufa.de/datenschutz" target="_blank>">www.schufa.de/datenschutz</a>
              eingesehen werden.</p>
            <p class="text-right">Diese Information wurde bereitgestellt von der SCHUFA Holding AG.</p>
          </div>

          <h3>Betrugsprophylaxe</h3>
          <p>Der Verantwortliche verarbeitet Ihre Daten zur Betrugsprophylaxe und tauscht Ihre Daten mit Auskunfteien,
            den Gesellschaften der VW Finanzdienstleistungsgruppe und ggf. Datenbankanbietern aus, soweit</p>
          <ul>
            <li>
              dies zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist (Art. 6 Abs. 1 S. 1 lit. c DSGVO). Die
              Datenverarbeitung ist insbesondere erforderlich zur Verhinderung von Geldwäsche,
              Terrorismusfinanzierung oder sonstiger strafbarer Handlungen, die zu einer Gefährdung des
              Vermögens des Verantwortlichen oder seiner Kunden führen können (z. B. nach
              dem Kreditwesengesetz, Geldwäschegesetz);
            </li>
            <li>
              dies zur Wahrung berechtigter Interessen des Verantwortlichen oder eines Dritten erforderlich ist (Art. 6
              Abs. 1 S. 1 lit. f DSGVO). Die Datenverarbeitung ist insbesondere erforderlich,
              um das Vermögen des Verantwortlichen, der VW Finanzdienstleistungsgruppe oder ihrer Kunden zu schützen und
              zukünftige strafbare Handlungen zu verhindern.
            </li>
          </ul>
          <p>Es gelten neben den „Allgemeinen Speicherfristen“ folgende Speicherfristen:
            <br>
            Ihre Daten, die
          </p>
          <ul>
            <li>
              aufgrund eines bestätigten Betrugs oder Betrugsversuches gekennzeichnet worden sind, werden dauerhaft im
              Rahmen der oben genannten Rechtsgrundlagen gespeichert;
            </li>
            <li>
              aufgrund eines nicht bestätigten Betrugsverdachts gekennzeichnet worden sind, werden längstens für 3 Jahre
              gespeichert.
            </li>
          </ul>

          <h3>Automatisierte Entscheidungen</h3>
          <p>Der Verantwortliche verarbeitet Ihre Daten für automatisierte Entscheidungen im Einzelfall. Diese
            Entscheidungsform kann insbesondere zu Zwecken der Antrags-, Bonitätsprüfung und
            Betrugsprophylaxe angewendet werden. Die Entscheidungen betreffen dabei gesetzliche (z. B.
            Geschäftsfähigkeit) und geschäftspolitische Vorgaben (z. B. negative Eigenerfahrungen)
            sowie anerkannte mathematisch-statistische Verfahren (z.B. Bonitätsscoring). Fällt die Entscheidung
            nachteilig für Sie aus, haben Sie das Recht, die getroffene Entscheidung durch
            Einschaltung einer Person überprüfen zu lassen. Die Verarbeitung Ihrer Daten zu den oben genannten Zwecken
            findet insbesondere statt, soweit</p>
          <ul>
            <li>
              dies für die Erfüllung eines Vertrages, den Sie geschlossen haben, oder zur Durchführung vorvertraglicher
              Maßnahmen erforderlich ist (Art. 6 Abs. 1 S. 1 lit. b DSGVO). Die Datenverarbeitung ist insbesondere
              erforderlich für die schnellstmögliche und digitale Bewertung und Bearbeitung
              Ihres Antrages, zur Optimierung der angebotenen Produkte und Prozesse
              und zum Schutz von Vermögenswerten des Verantwortlichen, der VW Finanzdienstleistungsgruppe und ihrer
              Kunden;
            </li>
            <li>
              dies von Ihrer freiwillig erteilten Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO) umfasst ist.
            </li>
          </ul>

          <h3>Kundenprofile</h3>
          <p>Der Verantwortliche bildet auf Grundlage Ihrer Daten und weiterer werberelevanter Daten (z. B.
            Nutzungsdaten, Daten zu Ihren Präferenzen) Ihr individuelles Kundenprofil und ordnet
            dieses bestimmten Kundensegmenten zu. Diese Ergebnisse nutzt der Verantwortliche für die
            zielgruppenspezifische Steuerung von Art, Inhalt und Häufigkeit von Werbemaßnahmen.
            Dies kann insbesondere dazu führen, dass Sie im Unterschied zu anderen Kunden bestimmte Werbung erhalten
            oder nicht erhalten. Dies geschieht, soweit</p>
          <ul>
            <li>
              dies zur Wahrung berechtigter Interessen des Verantwortlichen oder eines Dritten erforderlich ist (Art. 6
              Abs. 1 S. 1 lit. f DSGVO). Die Datenverarbeitung ist insbesondere erforderlich,
              um anhand einfacher Auswertungen von Nutzungsdaten und offensichtlicher Präferenzen Angebote besser auf
              Sie zuschneiden zu können und unerwünschte oder unpassende
              Angebote zu vermeiden (eingeschränkte Auswertungen);
            </li>
            <li>
              dies von Ihrer freiwillig erteilten Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO) umfasst ist
              (umfangreiche Auswertungen).
            </li>
          </ul>

          <h3>Individuelle Werbemaßnahmen</h3>
          <p>Der Verantwortliche verarbeitet Ihre Daten für Direktwerbung, sowohl für eigene Angebote als auch für
            Angebote der VW Finanzdienstleistungsgruppe, Ihres Handelspartners und des
            Herstellers, des Importeurs oder der Vertriebsgesellschaft Ihres Fahrzeuges oder von sonstigen berechtigten
            Dritten (z. B. Unternehmen aus den Branchen: Bank, Leasing, Versicherung
            und Mobilität), und tauscht dazu mit den vorgenannten Empfängern Ihre Daten aus. Daneben nutzt der
            Verantwortliche Online-Marketingdienste, um bestimmten Zielgruppen bei ihrer
            Nutzung von Online-Diensten interessengerechte Werbeinhalte anzuzeigen. Dazu übermittelt der Verantwortliche
            Ihre Daten (z. B. E-Mailadresse) in verschlüsselter Form (Hash-Wert) an
            die Online-Marketingdienste. Diese gleichen den Hash-Wert der übermittelten Daten mit den Hashwerten ihrer
            eigenen Nutzerdaten zur Identifikation ab. Dies geschieht jeweils, soweit</p>
          <ul>
            <li>
              dies zur Wahrung berechtigter Interessen des Verantwortlichen oder eines Dritten erforderlich ist (Art. 6
              Abs. 1 S. 1 lit. f DSGVO). Die Datenverarbeitung ist insbesondere erforderlich,
              um Ihnen auf Sie zugeschnittene Angebote zeitnah und zuverlässig zukommen lassen zu können und Ihnen auf
              Sie zugeschnittene Online-Werbung anzuzeigen;
            </li>
            <li>
              dies von Ihrer freiwillig erteilten Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO) umfasst ist.
            </li>
          </ul>
          <p>Ein Austausch mit sonstigen Empfängern erfolgt nur, soweit dies von Ihrer freiwillig erteilten Einwilligung
            (Art. 6 Abs. 1 S. 1 lit. a DSGVO) umfasst ist.</p>

          <h3>Testdatenmanagement</h3>
          <p>Der Verantwortliche sowie die VW Finanzdienstleistungsgruppe verarbeiten Ihre Daten im Rahmen der Erhaltung
            und Einführung von IT-Systemen und Dienstleistungen, soweit dies
            zur Wahrung berechtigter Interessen des Verantwortlichen oder eines Dritten und der betroffenen Personen
            erforderlich ist (Art. 6 Abs. 1 S. 1 lit. f DSGVO). Die Datenverarbeitung ist
            insbesondere erforderlich, um die Sicherheit, Funktionsfähigkeit und Zuverlässigkeit neuer und bestehender
            IT-Systeme und Dienstleistungen zu gewährleisten und vor Störungen und
            widerrechtlichen Eingriffen zu schützen, die die Verfügbarkeit, Authentizität, Vollständigkeit oder
            Vertraulichkeit von Daten beeinträchtigen können. Die Verarbeitung dieser Daten ist
            auch erforderlich, um eine dauerhaft hohe Qualität und Einheitlichkeit der angebotenen
            Produkte/Dienstleistungen zu gewährleisten und sie stetig zu optimieren.</p>
          <p>Es gilt neben den „Allgemeinen Speicherfristen“ folgende Speicherfrist: Für die Tests wird eine Kopie der
            hierfür erforderlichen Daten erstellt. Diese Kopie wird längstens für 1 Jahr
            gespeichert.</p>

          <h3>Betroffenenrechte</h3>
          <p>Sie haben das Recht:</p>
          <ul>
            <li>
              gemäß Art. 15 DSGVO Auskunft über Ihre von dem Verantwortlichen verarbeiteten personenbezogenen Daten zu
              verlangen;
            </li>
            <li>
              gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer beim
              Verantwortlichen gespeicherten personenbezogenen Daten zu verlangen;
            </li>
            <li>
              gemäß Art. 17 DSGVO die Löschung Ihrer beim Verantwortlichen gespeicherten personenbezogenen Daten zu
              verlangen, sofern die gesetzlichen Voraussetzungen vorliegen;
            </li>
            <li>
              gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, sofern
              die gesetzlichen Voraussetzungen vorliegen;
            </li>
            <li>
              gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie dem Verantwortlichen bereitgestellt haben, in
              einem strukturierten, gängigen und maschinenlesbaren Format zu
              erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen;
            </li>
            <li>
              gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit gegenüber dem Verantwortlichen zu
              widerrufen;
            </li>
            <li>
              gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür an
              die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder unseres
              Unternehmenssitzes wenden.
            </li>
          </ul>
          <p>
            Möchten Sie von Ihren Betroffenenrechten Gebrauch machen, dann genügt eine E-Mail an: <a
              href="mailto:betroffenenrechte@volkswagenbank.de"> betroffenenrechte&#64;volkswagenbank.de</a>
            <br>
            Darüberhinausgehender Kontakt zum Datenschutzbeauftragten (neben der unter „Verantwortlicher“ genannten
            Anschrift): <a href="mailto:dsb@volkswagenbank.de"> dsb&#64;volkswagenbank.de</a>
          </p>

          <div class="border p-2 m-3">
            <h3>Widerspruchsrecht</h3>
            <p>
              <b>Sie haben das Recht, Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten einzulegen,
                soweit dafür Gründe vorliegen, die sich aus Ihrer besonderen
                Situation ergeben (Art. 21 Abs. 1 DSGVO). Weiter haben Sie das Recht, ohne Begründung Widerspruch gegen
                Direktwerbung und ein damit in Verbindung stehendes
                Werbeprofiling einzulegen (Art. 21 Abs. 2 DSGVO).</b>
            </p>
            <p>
              Möchten Sie von Ihrem Widerspruchsrecht Gebrauch machen, genügt eine E-Mail an <a
                href="mailto:betroffenenrechte@volkswagenbank.de">betroffenenrechte&#64;volkswagenbank.de</a></p>
            <p>
          </div>
          <p class="text-right"> Stand: September 2022</p>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>