import {Component} from '@angular/core';
import {environment} from '@env/environment';
import {Title} from '@angular/platform-browser';
import {AngularFaviconService} from 'angular-favicon';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  public readonly BRAND_NAME = environment.brandName;
  public readonly FAV_URL = environment.shortName ? 'assets/favicons/favicon_' + environment.shortName + '.ico' : 'favicon.ico';

  constructor(private titleService: Title, private ngxFavicon: AngularFaviconService) {
    this.titleService.setTitle(this.BRAND_NAME);
    this.ngxFavicon.setFavicon(this.FAV_URL);
  }

}
