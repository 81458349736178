<div class="c2-overlay" (click)="closeOverlay($event)">
  <div class="c2-wizard-container">
    <div class="row">
      <div class="col-12 text-right">
        <button mat-icon-button class="text-primary" (click)="closeFrame(false)">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="col-12" *ngIf="!invalidAmount">
        <change-rate [annuities$]="annuities$" [desiredDuration]="desiredDuration" (loadedEmitter)="loaded = true"
                     (planEmitter)="setCurrentPlan($event)" [interestFreeRatesMerchant]="interestFreeRatesMerchant"
                     [isOverlay]="!isCheckout"></change-rate>
      </div>

      <div class="col-12" *ngIf="invalidAmount">
        <h1>Ungültiger Betrag</h1>
        <p>Der Betrag von {{ amount | currencyPostfix }} kann mit {{ BRAND_NAME }} nicht
          finanziert werden.
          Bitte wählen Sie eine andere Bezahlmethode aus oder passen Sie den Einkauf an.</p>
      </div>

      <div class="col-12 text-center mt-3" *ngIf="loaded && checkoutCallback">
        <button mat-button class="text-primary" (click)="closeFrame(true)">Direkt zum Checkout
        </button>
      </div>
    </div>
  </div>
</div>


<script type="text/javascript">
    if (window.addEventListener) {
        window.addEventListener("message", onMessage, false);
    } else if (window.attachEvent) {
        window.attachEvent("onmessage", onMessage, false);
    }

    function onMessage(event) {

        var url = (window.location != window.parent.location)
            ? document.referrer
            : document.location.href;
        url = url.replace(/\/$/, "");

        if (!url.startsWith(event.origin)) {
            return;
        }

        var data = event.data;
        if (data.function === 'c2UpdateSession') {
            window.c2PurchaseAmount = data.c2PurchaseAmount;
            window.dispatchEvent(new Event("updateAmountEvent"));
        }

    }
</script>
