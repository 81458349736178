<div class="container">
  <div class="row">
    <div class="col-12 mb-5">
      <nav-logo></nav-logo>
    </div>
    <div class="col-12 col-md-8 offset-md-2">
      <div class="card shadow-sm">
        <div class="card-body">
          <h1>Schlichtungsstellen</h1>
          <h3>1. Volkswagen Bank GmbH</h3>
          <p>
            Die Bank nimmt am Streitbeilegungsverfahren der Verbraucherschlichtungsstelle "Ombudsmann der privaten
            Banken" (<a href="https://bankenombudsmann.de/" target="_blank">www.bankenombudsmann.de</a>) teil. Dort hat
            der Verbraucher die Möglichkeit, zur Beilegung einer Streitigkeit mit der Bank den Ombudsmann der privaten
            Banken anzurufen. Betrifft der Beschwerdegegenstand eine Streitigkeit über einen Zahlungsdienstevertrag (§
            675f des Bürgerlichen Gesetzbuches), können auch Kunden, die nicht Verbraucher sind, den Ombudsmann der
            privaten Banken anrufen.
          </p>
          <p>
            Näheres regelt die "Verfahrensordnung für die Schlichtung von Kundenbeschwerden im deutschen Bankgewerbe",
            die auf Wunsch zur Verfügung gestellt wird oder im Internet unter <a href="https://bankenverband.de/"
                                                                                 target="_blank">www.bankenverband.de</a>
            abrufbar ist. Die Beschwerde ist in Textform (z. B. mittels Brief, Telefax oder E-Mail) an die
            Kundenbeschwerdestelle beim Bundesverband deutscher Banken e. V., Postfach 040307, 10062 Berlin, Telefax:
            030 1663-3169, E-Mail: <a href="mailto:ombudsmann@bdb.de">ombudsmann&#64;bdb.de</a>, zu richten.
          </p>
          <h3>2. Europäische Online-Streitbeilegungsplattform</h3>
          <p>
            Die Europäische Kommission hat unter <a href="https://ec.europa.eu/consumers/odr" target="_blank">http://ec.europa.eu/consumers/odr</a>
            eine europäische Online-Streitbeilegungsplattform (OS-Plattform) errichtet. Die OS-Plattform kann ein
            Verbraucher für die außergerichtliche Beilegung einer Streitigkeit aus Online-Verträgen mit einem in der EU
            niedergelassenen Unternehmen nutzen.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
