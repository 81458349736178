import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BasePurchaseInfoResponse} from '@app/core/backend/response/base-purchase-info.response';
import {DateUtil} from '@app/util/static-services/date.util';
import {BackendService} from './core';
import {StringUtil} from './util/static-services/string.util';
import {environment} from '@env/environment';

@Component({
  templateUrl: './purchase-load.component.html'
})
export class PurchaseLoadComponent implements OnInit {

  public readonly BRAND_NAME: string = environment.brandName;

  readonly loginLinkStates = ['PENDING', 'VERIFY', 'PAID', 'PENDING_FULFILLMENT', 'PICKED_UP'];

  hasError: boolean;

  resultCode: number;

  basePurchaseInfo: BasePurchaseInfoResponse;

  shortCode: string;

  key: string;

  loading = true;

  emailSent: boolean;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private backendService: BackendService) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe(next => {
      this.shortCode = next.get('sc');
      this.key = next.get('key');
      if (StringUtil.isNotBlank(this.shortCode) && StringUtil.isNotBlank(this.key)) {
        this.trigger();
      } else {
        this.router.navigate(['/onboarding/main']);
        return;
      }
    });
  }

  private trigger() {
    this.backendService.getBasePurchaseInfo(this.shortCode, this.key).subscribe(next => {
      this.basePurchaseInfo = next;
      if (next.newUser) {
        this.initSession();
      } else {
        this.loading = false;
      }
    }, error => {
      this.handleError(error);
    });
  }

  private initSession() {
    this.backendService.initReferralSession(this.shortCode, this.key).subscribe(next => {
      if (!next || !next.customerId) {
        this.router.navigate(['/onboarding/main']);
        return null;
      }
      this.router.navigate(['/onboarding/purchase/overview']);
    }, err => {
      this.handleError(err);
    });
  }

  private handleError(error: any) {
    this.hasError = true;
    this.loading = false;
    if (error.resultCode) {
      this.resultCode = +error.resultCode;
    } else {
      this.router.navigate(['/error']);
    }
  }

  goToLogin() {
    this.router.navigate(['/portal/login']);
  }

  showLoginLink(): boolean {
    return this.loginLinkStates.findIndex(p => p === this.basePurchaseInfo.purchaseState) >= 0;
  }

  asDate(date: any): Date {
    return DateUtil.makeSafariValid(date);
  }
}
