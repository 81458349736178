import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class TriggerSimulationService {

  private readonly endpoint = environment.triggerSimulationUrl;


  constructor(private http: HttpClient) { }

  public triggerSimulation(request: any): Observable<any> {
    return this.http.post(this.endpoint, request, httpOptions);
  }
}
