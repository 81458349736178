import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {environment} from '@env/environment';


/**
 * Solution provided by https://stackoverflow.com/a/51059505
 */
@Injectable({
  providedIn: 'root'
})
export class RedirectGuard  {

  readonly productType = environment.productType;

  constructor() {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.productType === route.data['productType']) {
      window.location.href = route.data['externalUrl'];
      return false;
    }
    return true;
  }
}